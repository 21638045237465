.ca {
    .ca-list-panel {
        background-color: #1a1d24;
    }

    .ca-certificate {
        // background-color: #1a1d24;

        .rs-form-control-wrapper.plaintext {
            .rs-input {
                padding: 10px;
            }
        }
    }

    .rs-form-control-wrapper.plaintext {
        .rs-input {
            padding: 10px;
        }
    }
}

.dialogHolder {
    width: 80% !important;
    height: 80% !important;
}